import React from 'react'
import { createOffer, initiateConnection, startCall, sendAnswer, addCandidate, initiateLocalStream, listenToConnectionEvents } from '../modules/RTCModule'
import firebase from 'firebase/app'
import 'firebase/database'
import { doOffer, doAnswer, doLogin, doCandidate, doLeaveNotif } from '../modules/FirebaseModule'
import 'webrtc-adapter'
import VideoChat from './VideoChat'
import { Loader, Dimmer } from 'semantic-ui-react'
class VideoChatContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      database: null,
      connectedUser: null,
      localStream: null,
      localConnection: null
    }
    this.localVideoRef = React.createRef()
    this.remoteVideoRef = React.createRef()
  }

  componentDidMount = async () => {
    // firebase.initializeApp(config)

    // getting local video stream
    const localStream = await initiateLocalStream(true, true)
    const localConnection = await initiateConnection()
    this.setState({
      database: firebase.database(),
      localStream,
      localConnection
    })
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (this.state.database !== nextState.database) {
  //     return false
  //   }
  //   if (this.state.localStream !== nextState.localStream) {
  //     return false
  //   }
  //   if (this.state.localConnection !== nextState.localConnection) {
  //     return false
  //   }

  //   return true
  // }

  startCall = async (username, userToCall, flag) => {
    const { localConnection, database, localStream } = this.state
    listenToConnectionEvents(localConnection, username, userToCall, database, this.remoteVideoRef, doCandidate)
    // create an offer
    if (flag) {
      createOffer(localConnection, localStream, userToCall, doOffer, database, username)
    }
  }

  onLogin = async (username) => {
    return await doLogin(username, this.state.database, this.handleUpdate)
  }

  setLocalVideoRef = ref => {
    let { localStream } = this.state
    this.localVideoRef = ref
    if (ref) {
      this.localVideoRef.srcObject = localStream
    }
  }

  setRemoteVideoRef = ref => {
    this.remoteVideoRef = ref
  }

  handleUpdate = (notif, username) => {
    const { localConnection, database, localStream } = this.state

    if (notif) {
      console.log("notif.type", notif.type)

      switch (notif.type) {
        case 'offer':
          this.setState({
            connectedUser: notif.from
          })

          sendAnswer(localConnection, localStream, notif, doAnswer, database, username)
          break
        case 'answer':
          console.log("listenToConnectionEvents,answer")
          listenToConnectionEvents(localConnection, username, notif.from, database, this.remoteVideoRef, doCandidate)

          this.setState({
            connectedUser: notif.from
          })
          startCall(localConnection, notif)
          break
        case 'candidate':
          console.log('addCandidateaddCandidate', notif)
          addCandidate(localConnection, notif)
          break
        case 'leave':
          this.leavCall(username, notif.from)
          break;
        default:
          break
      }
    }
  }
  leavCall = async (user, userToCall) => {
    let { localStream, localConnection, database } = this.state
    if (localStream) {
      const tracks = await localStream.getTracks()
      tracks.forEach(track => {
        console.log("tracktrack", track)
        track.stop()
      })
      localConnection.close()
      this.setState({
        localStream: false,
        localConnection: false
      }, () => {
        doLeaveNotif(userToCall, database, user)

        if (window.webkit) {
          window.webkit.messageHandlers.webMesseges.postMessage(JSON.stringify({ dismiss: true }));
        }
        if (window.Android) {
          window.Android.showMessageInNative("dismiss")
        }
        if (this.props.dismiss) {
          this.props.dismiss()
        }
      })
    }else{
      if (window.webkit) {
        window.webkit.messageHandlers.webMesseges.postMessage(JSON.stringify({ dismiss: true }));
      }
      if (window.Android) {
        window.Android.showMessageInNative("dismiss")
      }
      if (this.props.dismiss) {
        this.props.dismiss()
      }
    }
  }

  render() {
    let { localStream } = this.state
    let { selectAppointment } = this.props
      return (
        <Dimmer page active style={{padding:'0'}}>

          {
            !localStream ?
              <Loader />
              :
              <VideoChat
                startCall={this.startCall}
                onLogin={this.onLogin}
                selectAppointment={selectAppointment}
                setLocalVideoRef={this.setLocalVideoRef}
                setRemoteVideoRef={this.setRemoteVideoRef}
                connectedUser={this.state.connectedUser}
                meetindID={this.props.meetindID}
                dismissCall={(username, userToCall) => this.leavCall(username, userToCall)}
                updateState={async (state) => {
                  console.log(state);
                  localStream.getVideoTracks()[0].enabled = state.video
                  localStream.getAudioTracks()[0].enabled = state.mic
                }}
              />
          }

        </Dimmer>
      )
    
  }
}

export default VideoChatContainer
