import React from 'react'
import './App.css'
import VideoChatContainer from './videoControlers/VideoChatContainer'

import Login from './login'
import firebase from 'firebase/app'
import config from './config'
import Appointments from './appointments/appointments'
import Payment from './payment'
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";

firebase.initializeApp(config)

function App() {
  return (
    <div className='app'>
      <Router>
        <Switch>
          <Route path="/call" component={VideoChatContainer} />
          <Route path='/pay' component={Payment} />
          <Route path="/login" component={Login} />
          <Route path="/" component={Appointments} />

        </Switch>
      </Router>
    </div>
  )
}

export default App
