import React, { Component } from "react";
import { GoSellElements } from "@tap-payments/gosell";
import { Button, Dimmer, Loader } from 'semantic-ui-react'
import { functions } from 'firebase'
class GoSellDemo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }
    callbackFunc = (response) => {
        console.log("responseresponse", response)
        let url = new URL(window.location.href)
        let bookingId = url.searchParams.get('booking_id')
        let userUID = url.searchParams.get('user')

        this.setState({ loading: true })
        if (response) {
            // console.log("responseresponse", response)
            if (response && response.id) {
                let createCustomer = functions().httpsCallable('createCustomer')
                createCustomer({
                    token: response.id,
                    userUID: userUID
                }).then(async (customerResponse) => {
                    console.log("datadata", customerResponse)
                    if (customerResponse && customerResponse.data && customerResponse.data.savedCard && customerResponse.data.customer) {
                        let { savedCard, customer } = customerResponse.data
                        console.log("inin")
                        let authResponse = await functions().httpsCallable('createToken')({
                            saved_card: {
                                card_id: savedCard.id,
                                customer_id: savedCard.customer
                            },
                            bookingId: bookingId,
                            customer: customer,
                            userUid: userUID
                        })
                        console.log("authResponseauthResponse", authResponse)

                        if (authResponse) {
                            let { data } = authResponse
                            let { transaction, id, status } = data
                            if ((status === "INITIATED") && transaction) {
                                window.location.href = transaction.url
                            } else if (status === "AUTHORIZED") {
                                console.log("authIDauthID", id)
                                if (window.ReactNativeWebView) {
                                    window.ReactNativeWebView.postMessage(JSON.stringify(data))
                                }
                                if (window.webkit) {
                                    window.webkit.messageHandlers.webMesseges.postMessage(JSON.stringify({ response: data }));
                                }
                                if (window.Android) {
                                    window.Android.showMessageInNative(JSON.stringify({ response: data }))
                                }
                                this.setState({ loading: false })

                            } else {
                                this.setState({ error: "Cannot process your card" }, () => {
                                    setTimeout(() => {
                                        this.setState({ error: false })
                                    }, 5000)
                                })
                            }
                        }
                    }
                }).catch((error) => {
                    this.setState({ error: error }, () => {
                        setTimeout(() => {
                            this.setState({ error: false })
                        }, 5000)
                    })
                    console.log("error", error)
                })
            }
        }

    }
    componentWillMount() {
        let url = new URL(window.location.href)
        let authId = url.searchParams.get('tap_id')
        let bookingId = url.searchParams.get('booking_id')

        console.log("authIDauthID", authId, bookingId)
        // if (bookingId) {
        //     firestore().collection('authorizing').doc(bookingId).get().then(async appointment => {
        //         let appointmentData = appointment.data()
        //         let { patient } = appointmentData
        //         let userData = await firestore().collection('users').doc(patient).get()
        //         // let { firstName, number, email, lastName, card, customer } = userData.data()

        //         console.log("userDatauserData", userData.data())
        //         console.log("appointmentDataappointmentData", appointmentData)

        //         this.setState({ appointmentData, userData: userData.data() })
        //     })
        // }
        if (authId) {
            this.setState({ loading: true })

            functions().httpsCallable('checkAuthStatus')({
                authId: authId
            }).then((results) => {
                let { data } = results
                let { status } = JSON.parse(data)
                console.log("datadata", data, status)
                if (status === "AUTHORIZED") {
                    this.setState({ loading: false }, () => {
                        if (window.ReactNativeWebView) {
                            window.ReactNativeWebView.postMessage(JSON.stringify({ authId: authId }))
                        }
                        if (window.webkit) {
                            window.webkit.messageHandlers.webMesseges.postMessage(JSON.stringify({ authId: authId }));
                        }
                        if (window.Android) {
                            window.Android.showMessageInNative(JSON.stringify({ authId: authId }))
                        }
                    })
                } else {
                    this.setState({
                        error: "Cannot process your card",
                        loading: false
                    }, () => {
                        setTimeout(() => {
                            this.setState({ error: false })
                        }, 5000)
                    })
                }
            })

        }
        // setTimeout(() => {
        //     // GoSell.showResult()
        //     GoSell.openPaymentPage()
        // }, 1000);

    }

    render() {
        let { direction } = this.props
        let { loading, error } = this.state
        return (
            <div className="App" style={{ padding: '16px 0' }}>

                {
                    loading &&
                    <Dimmer active={true} page>
                        <Loader>Processing Payment</Loader>
                    </Dimmer>
                }

                <div>
                    <GoSellElements
                        gateway={{
                            publicKey: process.env.REACT_APP_TAP_KEY ,
                            language: "en",
                            supportedCurrencies: "all",
                            supportedPaymentMethods: "all",
                            notifications: 'msg',
                            saveCardOption: true,
                            callback: (r) => this.callbackFunc(r),
                            labels: {
                                cardNumber: "Card Number",
                                expirationDate: "MM/YY",
                                cvv: "CVV",
                                cardHolder: "Name on Card",
                                actionButton: "Pay"
                            },
                            style: {
                                base: {
                                    color: '#535353',
                                    lineHeight: '18px',
                                    fontFamily: 'sans-serif',
                                    fontSmoothing: 'antialiased',
                                    fontSize: '16px',
                                    margin: '8px',
                                    '::placeholder': {
                                        color: 'rgba(0, 0, 0, 0.26)',
                                        fontSize: '15px'
                                    }
                                },
                                invalid: {
                                    color: 'red',
                                    iconColor: '#fa755a '
                                }
                            }
                        }}
                    />

                    <p id="msg"></p>
                </div>


                <div className='cartDetail' style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className='actionBtn'>
                        <Button color='blue' style={{ width: '200px' }} onClick={() => GoSellElements.submit()}>
                            {
                                direction === 'rtl' ?
                                    'ادفع'
                                    :
                                    'pay'
                            }

                        </Button>
                    </div>

                </div>
                {
                    error && <div style={{ color: 'red', textAlign: 'center', width: '100%', padding: '16px', fontSize: '22px' }}>{error} </div>
                }
            </div>
        );
    }
}

export default GoSellDemo;
