import React, { Component } from 'react'
import { auth } from 'firebase'
import { Button, Form, Segment } from 'semantic-ui-react'
import logo from './img/Powered_by_blue.png'
import PowerdBy from './img/PoweredBySinou.jpeg'

class Login extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }
    login() {
        let { email, password } = this.state
        if (email && password) {
            auth().signInWithEmailAndPassword(email, password).then(async (user) => {
                if (user) {
                    auth().currentUser.getIdTokenResult().then((role) => {
                        console.log("rolerole", role.claims)
                        if (role.claims.role === "doctor") {
                            window.location.href = '/'
                        } else {
                            auth().signOut()
                        }
                    })
                }
            }).catch(error => {
                alert(error)

            })
        } else {
            alert('please enter valid Email and Password!')
        }
    }
    formExampleInverted = () => (
        <Segment style={{ padding: '1em', maxWidth: '400px', minWidth: '300px', boxShadow: '0 0 8px 0 #cacaca' }}>
            <Form style={{ padding: '0', margin: '0' }}>
                <div style={{ borderBottom: '1px solid lightGray', fontSize: '22px', padding: '10px 0' }}>
                    Get Started
                </div>
                <Form.Group widths='equal' style={{ padding: '16px 0 0' }}>
                    <Form.Input fluid type='Email' label='Email' placeholder='Email' onChange={(e, { value }) => this.setState({ email: value })} />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Input type='password' fluid label='Password' placeholder='Password' onChange={(e, { value }) => this.setState({ password: value })} />
                </Form.Group>
                {/* <Form.Checkbox label='I agree to the Terms and Conditions' /> */}
                <div style={{ display: 'flex', padding: '16px 0 8px' }}>
                    <Button style={{ width: '100%' }} type='submit' color='blue' onClick={() => this.login()}>Login</Button>

                </div>
            </Form>
        </Segment>
    )
    render() {
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '34px 0' }}>
                        <img alt='' style={{ width: '80px', objectFit: 'cover' }} src={`${process.env.REACT_APP_LOGOURL}`} />
                    </div>
                    {
                        this.formExampleInverted()
                    }
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '16px 0' }}>
                        <img alt='' style={{ width: '100px', objectFit: 'cover' }} src={process.env.REACT_APP_NAME === 'doctory' ? logo : PowerdBy} />
                    </div>
                </div>

            </div>
        )
    }

}
export default Login