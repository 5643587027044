// const config = {
//   apiKey: "AIzaSyAksrn7-Nb1P87xOpdGXDjjLmKegH10ebQ",
//   authDomain: "webrtc-57b7b.firebaseapp.com",
//   projectId: "webrtc-57b7b",
//   storageBucket: "webrtc-57b7b.appspot.com",
//   messagingSenderId: "576642682139",
//   appId: "1:576642682139:web:d69df3c8785551e21c57be",
//   measurementId: "G-TN444BJYJC"
// }
var config = {

  test: {
    apiKey: "AIzaSyByBkaAPgJoIGkVaZNSXo0wOxWVIxGFCLQ",
    authDomain: "clinicaone-94719.firebaseapp.com",
    databaseURL: "https://clinicaone-94719.firebaseio.com",
    projectId: "clinicaone-94719",
    storageBucket: "clinicaone-94719.appspot.com",
    messagingSenderId: "613950287247",
    appId: "1:613950287247:web:823048a8dd0e6b74"
  },
  doctory: {
    apiKey: "AIzaSyCQCPo3y8jVy4XsS0et5UUny_vnB7BBoHo",
    authDomain: "doctory-aec48.firebaseapp.com",
    databaseURL: "https://doctory-aec48.firebaseio.com",
    projectId: "doctory-aec48",
    storageBucket: "doctory-aec48.appspot.com",
    messagingSenderId: "483988549007",
    appId: "1:483988549007:web:0612553c3f2e73d7"
  },
  clinica: {
    apiKey: "AIzaSyB3orE5DtytEP1M0flPzQDzDNBC0Xuaoqo",
    authDomain: "clincaone.firebaseapp.com",
    databaseURL: "https://clincaone.firebaseio.com",
    projectId: "clincaone",
    storageBucket: "clincaone.appspot.com",
    messagingSenderId: "107789857421",
    appId: "1:107789857421:web:640c66e14a0c81b6",
    measurementId: "G-5V4ESZ0LDT"
  },
  saeed:{
    apiKey: "AIzaSyCptU-wHmLHi64vU_zU_Q4SaLV839gWih8",
    authDomain: "m-saeed-811a8.firebaseapp.com",
    projectId: "m-saeed-811a8",
    storageBucket: "m-saeed-811a8.appspot.com",
    messagingSenderId: "756603383698",
    appId: "1:756603383698:web:44a010c5510c9386f05b4b",
    measurementId: "G-ERGJ1NEP1K"
  }



};


export default config[process.env.REACT_APP_NAME]
