import React, { Component } from 'react'
import { auth, firestore } from 'firebase'
import { Button, Dropdown, Dimmer } from 'semantic-ui-react'
import VideoChatContainer from '../videoControlers/VideoChatContainer'

import './appointments.css'
import moment from 'moment'
class Appointments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDate: {
                name: 'Today', date: moment().format('DD MMM YYYY')
            }
        }
    }
    componentDidMount() {
        auth().onAuthStateChanged(async (user) => {
            if (user) {
                let doctor = (await firestore().collection('doctors').doc(user.uid).get()).data()
                if (doctor) {
                    let appointments = []
                    let offlineAppointments = []

                    await firestore().collection('bookings').where('doctor', '==', user.uid).where('status', 'in', ['succeeded', 'accepted']).onSnapshot(snap => {
                        appointments = []
                        var count = 0
                        if (snap.empty) {
                            return this.setState({ appointments, doctor })
                        }
                        let service;
                        snap.forEach(async doc => {
                            let data = doc.data()
                            let patient = (await firestore().collection('users').doc(data.patient).get()).data()
                            switch (data.service) {
                                case "onlineConsult":
                                    service = { name: 'Online Consultation' }
                                    break;
                                case "inpersonConsult":
                                    service = { name: 'In Clinic Consultation' }
                                    break;
                                default:
                                    if (data.service) {
                                        let serviceData = (await firestore().collection('services').doc(data.service).get())
                                        service = serviceData ? serviceData.data() : { name: "" }
                                    }
                            }
                            data.id = doc.id
                            data.serviceData = service
                            data.patientData = patient
                            data.type = 'Online'
                            appointments.push(data)
                            count++
                            if (count === snap.size) {
                                this.getAllAppointments(appointments, offlineAppointments)

                                this.setState({ appointments, doctor })
                            }
                        })
                    })

                    await firestore().collection('offlineBookings').where('doctor', '==', user.uid).where('status', 'in', ['succeeded', 'accepted']).onSnapshot(snap => {
                        offlineAppointments = []
                        var count = 0
                        if (snap.empty) {
                            return this.setState({ offlineAppointments, doctor })
                        }
                        let service;
                        snap.forEach(async doc => {
                            let data = doc.data()
                            console.log("datadatadata", data)
                            let patient = (await firestore().collection('offlineUsers').doc(data.patient).get()).data()
                            switch (data.service) {
                                case "onlineConsult":
                                    service = { name: 'Online Consultation' }
                                    break;
                                case "inpersonConsult":
                                    service = { name: 'In Clinic Consultation' }
                                    break;
                                default:
                                    if (data.service) {
                                        let serviceData = (await firestore().collection('services').doc(data.service).get())
                                        service = serviceData ? serviceData.data() : { name: "" }
                                    }
                            }
                            data.id = doc.id
                            data.serviceData = service
                            data.patientData = patient
                            data.type = 'Offline'
                            offlineAppointments.push(data)
                            count++
                            if (count === snap.size) {
                                this.getAllAppointments(appointments, offlineAppointments)
                                this.setState({ offlineAppointments, doctor })
                            }
                        })
                    })

                } else {
                    auth().signOut()
                    window.history.replaceState(null, null, '/login')
                    window.history.go()
                }

            } else {
                auth().signOut()
                window.history.replaceState(null, null, '/login')
                window.history.go()
            }
        }
        )
    }
    getAllAppointments(onlineAppointments, offlineAppointments) {
        let allAppointments = onlineAppointments.concat(offlineAppointments)
        let optimizedAppointments = allAppointments && allAppointments.reduce((value, current) => {
            let formattedDate = moment(current.dateTS).format('DD MMM yy')
            if (value && value[formattedDate]) {
                value[formattedDate].sort((a, b) => a.dateTS - b.dateTS)
                value[formattedDate].push(current)
            } else {
                value[formattedDate] = [current]
            }
            return value
        }, {})
        this.setState({ allAppointments, optimizedAppointments })

    }
    joinMeeting(record) {
        let id = record.id
        firestore().collection('bookings').doc(id).update({
            status: 'succeeded',
            succeeded: Date.now(),
        }).then(() => {
            this.setState({ meetindID: id, selectAppointment: record })
        })

    }
    discharge(record) {
        let id = record.id
        firestore().collection('bookings').doc(id).update({
            status: 'discharged',
            discharged: Date.now()
        }).then(() => {
            this.setState({ dischargeModal: false })
        })

    }


    render() {
        let { doctor, dischargeModal, optimizedAppointments, selectedDate } = this.state
        let date = moment()
        let today = moment(date).format('DD MMM YYYY')
        let tomorrow = moment(date).add(1, 'day').format('DD MMM YYYY')
        return (
            <div style={{ display: 'flex' }}>
                <div className={this.state.meetindID ? 'appointmentsHidden' : 'appointments'}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '8px',
                        boxShadow: '0 0 15px 0px lightGray',
                        alignItems: 'center',
                        position: 'sticky',
                        top: '0',
                        background: '#21a2b7',
                        color: 'white',
                        height: '60px',
                    }}>
                        {/* <div style={{ width: '10%' }}></div> */}
                        <div style={{ fontSize: '24px', fontWeight: 'bolder' }}>
                            Appointments
                        </div>
                        <div style={{ width: '10%' }}>
                            {
                                doctor ?
                                    <Dropdown
                                        icon='none'
                                        style={{ display: 'flex' }}
                                        direction='left'
                                        trigger={
                                            <div>
                                                <img
                                                    alt=""
                                                    style={{
                                                        width: '35px',
                                                        height: '35px',
                                                        borderRadius: '50%',
                                                        cursor: 'pointer'
                                                    }} src={doctor ? doctor.profilePicture : ''} />
                                            </div>
                                        } >
                                        <Dropdown.Menu>
                                            <Dropdown.Item text='Logout' icon='sign-out' onClick={() => {
                                                auth().signOut()
                                            }} />

                                        </Dropdown.Menu>
                                    </Dropdown>
                                    : ''
                            }
                        </div>
                    </div>
                    <div className="filterContainer">
                        {
                            [{ name: 'Today', date: today }, { name: 'Tomorrow', date: tomorrow }, { name: 'Later', date: '' }].map(item => {
                                return (
                                    <div key={item.name} className={selectedDate && selectedDate.name === item.name ? "filterItemActive" : "filterItem"}
                                        onClick={() => {
                                            this.setState({ selectedDate: item })
                                        }}>
                                        {item.name}
                                    </div>
                                )

                            })}
                    </div>
                    {
                        optimizedAppointments && (selectedDate.name === 'Today' || selectedDate.name === 'Tomorrow') ?
                            <div key={selectedDate.date}>
                                <div style={{ fontSize: '18px', fontWeight: 'bolder', padding: '16px 12px 0' }}>{selectedDate.date}</div>
                                {
                                    optimizedAppointments[selectedDate.date] && optimizedAppointments[selectedDate.date].length ? optimizedAppointments[selectedDate.date].map((record, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className='cell'
                                            >
                                                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid lightGray', padding: '8px 0' }}>
                                                        <div>
                                                            <img
                                                                alt=""
                                                                style={{
                                                                    width: '50px',
                                                                    height: '50px',
                                                                    objectFit: 'cover',
                                                                    borderRadius: '30%',
                                                                }} src={record.patientData.profilePicture} />
                                                        </div>
                                                        <div style={{ padding: '8px' }}>
                                                            <div style={{}}>{record.patientName}</div>
                                                            <div className='details'>{record.patientData.birthday}</div>
                                                        </div>

                                                    </div>
                                                    <div style={{ padding: '8px 0', borderBottom: '1px solid lightGray' }}>
                                                        <div style={{ padding: '4px 0' }}>
                                                            <div className='details'>Clinic</div>
                                                            <div >{record.facilityName}</div>

                                                        </div>
                                                        <div style={{ padding: '4px 0' }}>
                                                            <div className='details'>Service</div>
                                                            {/* <div>{record.service}</div> */}
                                                            <div>{(record.serviceData && record.serviceData.name) || ""}</div>

                                                        </div>
                                                        <div style={{ padding: '4px 0' }}>
                                                            <div className='details'>Date</div>
                                                            <div>{record.date}</div>
                                                        </div>
                                                        <div style={{ padding: '4px 0' }}>
                                                            <div className='details'>Type</div>
                                                            <div >{record.type}</div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div style={{ padding: '8px 0', borderBottom: record.service === 'onlineConsult' ? '1px solid lightGray' : 'none' }}>
                                                    <div className='details'>Status</div>
                                                    <div style={{ textTransform: 'capitalize', color: '#1dbc8f' }}>{record.status === "succeeded" ? "Active" : record.status}</div>
                                                </div>
                                                {
                                                    record.service === 'onlineConsult' ?
                                                        <div style={{ padding: '16px 0 0', display: 'flex' }}>
                                                            {
                                                                record.status === "succeeded" &&
                                                                <Button disabled={this.state.meetindID} onClick={() => this.setState({ dischargeModal: record })} color='red' style={{ width: '100%', backgroundColor: '#red' }}>Discharge</Button>

                                                            }
                                                            <Button disabled={this.state.meetindID} onClick={() => this.joinMeeting(record)} color='blue' style={{ width: '100%', backgroundColor: '#1dbc8f' }}>Attend</Button>
                                                        </div>
                                                        : ''
                                                }


                                            </div>

                                        )
                                    })
                                        :
                                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '45vh' }}>
                                            No appointments yet!
                                        </div>
                                }
                            </div>

                            :
                            optimizedAppointments && Object.keys(optimizedAppointments).map(key => {
                                if (key === today || key === tomorrow) {
                                    return false
                                }
                                let appointments = optimizedAppointments[key]
                                return (
                                    <div key={key}>
                                        <div style={{ fontSize: '18px', fontWeight: 'bolder', padding: '16px 12px 0' }}>{key}</div>
                                        {
                                            appointments && appointments.length ? appointments.map((record, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className='cell'
                                                    >
                                                        <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                            <div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid lightGray', padding: '8px 0' }}>
                                                                <div>
                                                                    <img
                                                                        alt=""
                                                                        style={{
                                                                            width: '50px',
                                                                            height: '50px',
                                                                            objectFit: 'cover',
                                                                            borderRadius: '30%',
                                                                        }} src={record.patientData.profilePicture} />
                                                                </div>
                                                                <div style={{ padding: '8px' }}>
                                                                    <div style={{}}>{record.patientName}</div>
                                                                    <div style={{}}>{record.type}</div>
                                                                    <div className='details'>{record.patientData.birthday}</div>
                                                                </div>

                                                            </div>
                                                            <div style={{ padding: '8px 0', borderBottom: '1px solid lightGray' }}>
                                                                <div style={{ padding: '4px 0' }}>
                                                                    <div className='details'>Clinic</div>
                                                                    <div >{record.facilityName}</div>

                                                                </div>
                                                                <div style={{ padding: '4px 0' }}>
                                                                    <div className='details'>Service</div>
                                                                    {/* <div>{record.service}</div> */}
                                                                    <div>{(record.serviceData && record.serviceData.name) || ""}</div>

                                                                </div>
                                                                <div style={{ padding: '4px 0' }}>
                                                                    <div className='details'>Date</div>
                                                                    <div>{record.date}</div>
                                                                </div>
                                                                <div style={{ padding: '4px 0' }}>
                                                                    <div className='details'>Type</div>
                                                                    <div >{record.type}</div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div style={{ padding: '8px 0', borderBottom: record.service === 'onlineConsult' ? '1px solid lightGray' : 'none' }}>
                                                            <div className='details'>Status</div>
                                                            <div style={{ textTransform: 'capitalize', color: '#1dbc8f' }}>{record.status === "succeeded" ? "Active" : record.status}</div>
                                                        </div>
                                                        {
                                                            record.service === 'onlineConsult' ?
                                                                <div style={{ padding: '16px 0 0', display: 'flex' }}>
                                                                    {
                                                                        record.status === "succeeded" &&
                                                                        <Button disabled={this.state.meetindID} onClick={() => this.setState({ dischargeModal: record })} color='red' style={{ width: '100%', backgroundColor: '#red' }}>Discharge</Button>

                                                                    }
                                                                    <Button disabled={this.state.meetindID} onClick={() => this.joinMeeting(record)} color='blue' style={{ width: '100%', backgroundColor: '#1dbc8f' }}>Attend</Button>
                                                                </div>
                                                                : ''
                                                        }


                                                    </div>

                                                )
                                            })
                                                :
                                                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '45vh' }}>
                                                    No appointments yet!
                                                </div>
                                        }
                                    </div>
                                )
                            })
                    }


                </div>
                {
                    process.env.REACT_APP_ACCEPT_ONLINE ?
                        this.renderMeetingSection()
                        : ''
                }
                {
                    dischargeModal && <Dimmer page active>
                        <div>
                            <div style={{ padding: '16px' }}>
                                Are you sure you want to discharge this patient?
                            </div>
                            <div >
                                <Button style={{ margin: '8px' }} basic color="red" onClick={() => this.setState({ dischargeModal: false })}>Cancel</Button>
                                <Button style={{ margin: '8px' }} color="red" onClick={() => this.discharge(dischargeModal)}>Discharge</Button>
                            </div>
                        </div>
                    </Dimmer>
                }
            </div>

        )
    }

    renderMeetingSection() {
        return (
            <div className={this.state.meetindID ? 'videoScreenActive' : 'videoScreen'}>
                {
                    this.state.meetindID ?
                        <VideoChatContainer selectAppointment={this.state.selectAppointment} meetindID={this.state.meetindID} dismiss={() => this.setState({ meetindID: false })} />
                        :
                        <div>
                            Select Appointment to Create room
                        </div>

                }


            </div>
        )
    }


}
export default Appointments