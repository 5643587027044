
// import {SFrame}	from "../sframe.js";

export const createOffer = async (connection, localStream, userToCall, doOffer, database, username) => {
  try {
    connection.addStream(localStream)

    const offer = await connection.createOffer()
    await connection.setLocalDescription(offer)

    doOffer(userToCall, offer, database, username)
  } catch (exception) {
    console.error(exception)
  }
}

export const initiateLocalStream = async (video, audio) => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: video,
      audio: audio
    }).catch(error => {
      alert(error)
      console.log('errorerror', error)
    })
    return stream
  } catch (exception) {
    console.error(exception)
  }
}
export const initiateConnection = async () => {
  try {
    // using Google public stun server
    var configuration = {
      iceServers: [
        {
          urls: 'turn:numb.viagenie.ca',
          credential: 'muazkh',
          username: 'webrtc@live.com'
        },
        {
        urls: [
          'stun:stun.l.google.com:19302',
          'stun:stun1.l.google.com:19302',
          'stun:stun2.l.google.com:19302',
          'stun:stun3.l.google.com:19302',
          'stun:stun4.l.google.com:19302',
          'stun:stun5.l.google.com:19302',
        ]
      }
    ],
      // forceEncodedVideoInsertableStreams: true,
      // forceEncodedAudioInsertableStreams: true,
      // encodedInsertableStreams: true
    }

    const conn = new RTCPeerConnection(configuration)
    conn.addEventListener('connectionstatechange', event => {
      console.log("conn.connectionState", conn.connectionState, event)
    });
    // conn.onsignalingstatechange(signal=>{
    //   console.log('signalsignal',signal)
    // })
    return conn
  } catch (exception) {
    console.error(exception)
  }
}

export const listenToConnectionEvents = (conn, username, remoteUsername, database, remoteVideoRef, doCandidate) => {
  if (conn) {
    console.log("connconn",conn)

    conn.onicecandidate = function (event) {
      console.log("conn.onicecandidate")

      if (event.candidate) {
        doCandidate(remoteUsername, event.candidate, database, username)
      }
    }
    conn.ontrack = function (e) {
      console.log("conn.ontrack", e)

      if (remoteVideoRef.srcObject !== e.streams[0]) {
        remoteVideoRef.srcObject = e.streams[0]
      }
    }
  }
}

export const sendAnswer = async (conn, localStream, notif, doAnswer, database, username) => {
  try {
    if (conn) {
      // let test = await conn.getTracks()
      // console.log("connconn",test)
      console.log("connconn", conn)
      try {
        conn.addStream(localStream)
      } catch (error) {
        console.log("addStreamerror", error)
      }

      const offer = JSON.parse(notif.offer)
      conn.setRemoteDescription(offer)

      // create an answer to an offer
      const answer = await conn.createAnswer()
      conn.setLocalDescription(answer)

      doAnswer(notif.from, answer, database, username)
    }

  } catch (exception) {
    console.error(exception)
  }
}

export const startCall = (yourConn, notif) => {
  const answer = JSON.parse(notif.answer)
  if (yourConn) {
    yourConn.setRemoteDescription(answer)
  }
}

export const addCandidate = (yourConn, notif) => {
  // apply the new received candidate to the connection
  console.log("addCandidateaddCandidate",notif,yourConn)
  const candidate = JSON.parse(notif.candidate)
  if (yourConn) {
    yourConn.addIceCandidate(new RTCIceCandidate(candidate)).catch((error => console.log("errorerror", error)));
  }
}
